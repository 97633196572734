import { Button, Flex, Form, Input, Modal, type ModalProps } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { useAdminTaskController_create } from '@api-client/generated/AdminTaskController/create';
import { useAdminUserController_getUsers } from '@api-client/generated/AdminUserController/getUsers';
import { Schemas } from '@api-client/generated/types';
import { Select } from '@components';
import { useTasksStore } from '@store';

import * as S from './styled';

type ChatModalCreateNewProps = {
  company: Schemas.Company;
  onCancel: VoidFunction;
} & ModalProps;

const ChatModalCreateNew = ({
  open,
  company,
  onCancel,
  ...props
}: ChatModalCreateNewProps) => {
  const { companyId, id: taskId } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { addTask } = useTasksStore();

  const { data: users = [], isPending: loadingUsers } =
    useAdminUserController_getUsers();

  const { mutate: createTask } = useAdminTaskController_create();

  const handleSubmit = (values: Schemas.AdminTaskDto) =>
    createTask(
      { requestBody: { ...values, companyId: companyId! } },
      {
        onSuccess: (task) => {
          navigate(`/chat-tasks/${companyId}/chats/${taskId}`);

          addTask(task);

          form.resetFields();
          onCancel();
        },
      }
    );

  return (
    <Modal
      width={600}
      open={open}
      title="Create new chat"
      footer={null}
      onCancel={onCancel}
      destroyOnClose
      centered
      {...props}
    >
      <S.Inner gap={24} vertical>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          requiredMark={false}
          initialValues={{
            isPrivate: false,
          }}
        >
          <Flex gap={32} vertical>
            <div>
              <Form.Item label="Company">
                <Input value={company?.name} size="large" disabled />
              </Form.Item>

              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true }]}
              >
                <Input size="large" />
              </Form.Item>

              <Form.Item
                label="Comment message for client"
                name="description"
                rules={[{ required: true }]}
              >
                <Input.TextArea rows={4} size="large" />
              </Form.Item>

              <Form.Item label="Assignee" name="assigneeId">
                <Select
                  options={users.map((user) => ({
                    label: user.fullName,
                    value: user.id,
                  }))}
                  size="large"
                  loading={loadingUsers}
                  optionFilterProp="label"
                  showSearch
                />
              </Form.Item>
            </div>

            <Form.Item noStyle>
              <Flex justify="flex-end" gap={12}>
                <Button onClick={onCancel} size="large">
                  Cancel
                </Button>

                <Button type="primary" htmlType="submit" size="large">
                  Create chat and notify client
                </Button>
              </Flex>
            </Form.Item>
          </Flex>
        </Form>
      </S.Inner>
    </Modal>
  );
};

export default ChatModalCreateNew;
