import { Flex } from 'antd';

import { BrandAvatarLogotype } from '@assets';
import { colors } from '@theme';

import * as S from './styled';

type ChatDialogsItemProps = {
  title: string;
  date: string;
  unseenMessagesCount: number;
  isArchived: boolean;
  isSelected: boolean;
  isGeneral: boolean;
  onClick: () => void;
};

const ChatDialogsItem = ({
  title,
  date,
  unseenMessagesCount,
  isArchived,
  isSelected,
  isGeneral,
  onClick,
}: ChatDialogsItemProps) => (
  <S.Item align="center" gap={12} isSelected={isSelected} onClick={onClick}>
    {isGeneral && (
      <S.Avatar>
        <BrandAvatarLogotype color={colors.white} />
      </S.Avatar>
    )}

    <S.Inner gap={2} vertical>
      <Flex align="center" justify="space-between" gap={36}>
        <S.Title type={isArchived ? 'archived' : 'active'}>
          {isGeneral ? 'General chat' : title}
        </S.Title>

        {unseenMessagesCount ? (
          <S.Count isSelected={isSelected}>{unseenMessagesCount}</S.Count>
        ) : null}
      </Flex>

      <Flex align="center" justify="space-between" gap={4}>
        {isGeneral && (
          <S.Details isSelected={isSelected}>
            For all questions and requests
          </S.Details>
        )}

        {!isGeneral && (
          <S.Status
            type={isArchived ? 'archived' : 'active'}
            isSelected={isSelected}
          >
            {isArchived ? 'Archived ' : 'Active chat'}
          </S.Status>
        )}

        <S.Date isSelected={isSelected} isGeneral={isGeneral}>
          {date}
        </S.Date>
      </Flex>
    </S.Inner>
  </S.Item>
);

export default ChatDialogsItem;
