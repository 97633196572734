import { Schemas } from '@api-client/generated/types';
import countries from '@data/countries.json';

export const getAddressLine = (address: Schemas.Address): string => {
  const fullAddress = [];

  if (!address) return '';

  const countryName = address?.countryCode
    ? countries.find((country) => country.code === address.countryCode)?.name
    : null;

  if (address?.houseNumber || address?.street) {
    fullAddress.push(`${address?.houseNumber || ''} ${address?.street}`);
  }

  if (address?.postalCode) {
    fullAddress.push(address?.postalCode);
  }

  if (address?.city) {
    fullAddress.push(address?.city);
  }

  if (countryName) {
    fullAddress.push(countryName);
  }

  return fullAddress.join(', ');
};
