import { Schemas } from '@api-client/generated/types';

type ChatMessage = Schemas.ChatMessage;
type User = Schemas.User;

export type GroupedMessages = {
  author: User;
  messages: ChatMessage[];
  isUnreadMessages: boolean;
  isAuthorAdmin: boolean;
};

export const getGroupedMessagesById = (
  messages: ChatMessage[],
  accountId: string
): GroupedMessages[] =>
  messages.reduce<GroupedMessages[]>((groups, message) => {
    const lastGroup = groups.at(-1);

    if (
      lastGroup &&
      lastGroup.author.id === message.author.id &&
      lastGroup.isAuthorAdmin === message.isAuthorAdmin
    ) {
      lastGroup.messages.push(message);

      if (!lastGroup.isAuthorAdmin) {
        lastGroup.isUnreadMessages ||= !message.seenData?.some(
          (seen) => seen.userId === accountId
        );
      }
    } else {
      groups.push({
        author: message.author,
        isAuthorAdmin: message.isAuthorAdmin,
        messages: [message],
        isUnreadMessages:
          !message.isAuthorAdmin &&
          !message.seenData?.some((seen) => seen.userId === accountId),
      });
    }

    return groups;
  }, []);
