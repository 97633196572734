import { Modal, type ModalProps } from 'antd';

import { Schemas } from '@api-client/generated/types';
import { FilePreview } from '@entities';

type TaskFile = Schemas.TaskFile;

type ChatModalFileViewerProps = {
  file: TaskFile;
  onCancel: () => void;
} & ModalProps;

const ChatModalFileViewer = ({
  open,
  file,
  onCancel,
  ...props
}: ChatModalFileViewerProps) => (
  <Modal
    width={557}
    open={open}
    title={file?.fileName || 'File'}
    footer={null}
    onCancel={onCancel}
    destroyOnClose
    centered
    {...props}
  >
    <FilePreview file={file} />
  </Modal>
);

export default ChatModalFileViewer;
