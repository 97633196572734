import { FC, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAdminCompanyController_findAll } from '@api-client/generated/AdminCompanyController/findAll';
import { DEFAULT_TIMEOUT_FOR_SEARCH } from '@constants';
import { SearchControl } from '@entities';

import ChatSidebarList from '../ChatSidebarList';
import * as S from './styled';

const ChatSidebar: FC = () => {
  const [filterSearch, setFilterSearch] = useState(null);

  const { data: companies = [], isLoading } = useAdminCompanyController_findAll(
    {
      params: {
        term: filterSearch!,
      },
    }
  );

  const handleSearch = useDebouncedCallback((value) => {
    setFilterSearch(value);
  }, DEFAULT_TIMEOUT_FOR_SEARCH);

  return (
    <S.Container gap={20} vertical>
      <SearchControl
        placeholder="Look up company"
        onChange={(e) => handleSearch(e.target.value)}
        fitWidth
        isInversedTheme
      />

      <ChatSidebarList companies={companies} isLoading={isLoading} />
    </S.Container>
  );
};

export default ChatSidebar;
