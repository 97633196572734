import { Flex } from 'antd';

import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Group = styled(Flex)`
  margin-top: 22px;
  padding: 0 30px;
`;

export const Inner = styled(Flex)`
  flex: 1;
`;

export const Header = styled(Flex)`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
  padding: 0 16px;
`;

export const Name = styled.strong`
  font-weight: 600;
`;
