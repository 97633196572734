import { Button } from 'antd';

import * as S from './styled';

type ChatPanelReadOverlayProps = {
  onClick: VoidFunction;
};

const ChatPanelReadOverlay = ({ onClick }: ChatPanelReadOverlayProps) => (
  <S.Overlay align="center" justify="center">
    <Button type="primary" onClick={onClick} size="large">
      Response to the request
    </Button>
  </S.Overlay>
);

export default ChatPanelReadOverlay;
