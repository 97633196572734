import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  type ModalProps,
  Spin,
  Upload,
} from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { useState } from 'react';

import { useAdminTaskFileController_create } from '@api-client/generated/AdminTaskFileController/create';
import { Schemas } from '@api-client/generated/types';
import { IconPlus } from '@assets';

import ChatFilesList from '../../files/ChatFilesList';
import * as S from './styled';

type ChatModalUploadFilesProps = {
  selectedId: string;
  companyId: string;
  actionCreateMessage: (body: string, fileIds: string[]) => void;
  onCancel: VoidFunction;
} & ModalProps;

const ChatModalUploadFiles = ({
  companyId,
  selectedId,
  open,
  actionCreateMessage,
  onCancel,
  ...rest
}: ChatModalUploadFilesProps) => {
  const [files, setFiles] = useState<Schemas.TaskFile[]>([]);

  const [form] = Form.useForm();

  const { mutate: uploadFile, isPending: isLoading } =
    useAdminTaskFileController_create();

  const handleFileAttach = (options: UploadRequestOption) => {
    const formData = new FormData();

    formData.append('file', options.file);
    formData.append('taskId', selectedId);

    uploadFile(
      {
        parameter: {
          companyId,
        },
        // eslint-disable-next-line
        // @ts-expect-error
        requestBody: formData,
      },
      {
        onSuccess: (file) => setFiles((prevState) => [file, ...prevState]),
      }
    );
  };

  const handleDeleteFile = (id: string) =>
    setFiles((prevState) => prevState.filter((file) => file.id !== id));

  const handleCancel = () => {
    setFiles([]);
    form.resetFields();

    onCancel();
  };

  const handleSubmit = (values: { message: string }) => {
    actionCreateMessage(
      values.message,
      files.map((file) => file.id)
    );

    handleCancel();
  };

  return (
    <Modal
      width={504}
      open={open}
      title="Upload files"
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      centered
      {...rest}
    >
      <S.Inner gap={24} vertical>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          requiredMark={false}
        >
          <Spin spinning={isLoading}>
            <ChatFilesList dataSource={files} onRemove={handleDeleteFile} />
          </Spin>

          <Upload customRequest={handleFileAttach} showUploadList={false}>
            <S.Attach>
              <IconPlus />
              Add file
            </S.Attach>
          </Upload>

          <Form.Item
            name="message"
            rules={[
              {
                required: true,
                message: 'Add message',
              },
            ]}
          >
            <Input.TextArea rows={4} placeholder="Add message" size="large" />
          </Form.Item>

          <Form.Item noStyle>
            <Flex justify="flex-end" gap={12}>
              <Button onClick={handleCancel} size="large">
                Cancel
              </Button>

              <Button type="primary" htmlType="submit" size="large">
                Upload
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </S.Inner>
    </Modal>
  );
};

export default ChatModalUploadFiles;
