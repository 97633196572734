import { Spin } from 'antd';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { Schemas } from '@api-client/generated/types';
import { useAccount } from '@hooks';
import { useSocketChat } from '@hooks-socket';
import { useTasksStore } from '@store';
import { getGroupedMessagesById, wrapTextLinesInParagraphs } from '@utils';

import ChatMessagesList from '../../messages/ChatMessagesList';
import ChatModalCreateNew from '../../modals/ChatModalCreateNew';
import ChatModalUploadFiles from '../../modals/ChatModalUploadFiles';
import ChatPanelFormSend from '../ChatPanelFormSend';
import ChatPanelHeader from '../ChatPanelHeader';
import * as S from './styled';

type ChatPanelProps = {
  taskId: string;
  generalTaskId: string;
  company: Schemas.Company;
  details: Schemas.Task;
  isGeneral: boolean;
};

const ChatPanel = (props: ChatPanelProps) => {
  const { account } = useAccount();

  if (!account) {
    throw new Error('Account is not defined');
  }

  const [modalAttachVisible, setModalAttachVisible] = useState(false);
  const [modalCreateVisible, setModalCreateVisible] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [disabledForm, setDisabledForm] = useState(true);

  const { readMessagesOfTask } = useTasksStore();

  const {
    messages,
    isLoading,
    createMessage,
    deleteMessage,
    markAsSeenMessage,
  } = useSocketChat({
    isEffectByCreate: modalCreateVisible,
    companyId: props.details.companyId,
    taskId: props.taskId,
    generalTaskId: props.generalTaskId,
    isGeneral: props.isGeneral,
  });

  const groupedMessages = useMemo(
    () => getGroupedMessagesById(messages, account.id),
    [messages, account]
  );

  const isUnreadMessages = useMemo(
    () => _.some(groupedMessages, { isUnreadMessages: true }),
    [groupedMessages]
  );

  useEffect(() => {
    if (newMessage) {
      setNewMessage('');
      setDisabledForm(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.taskId]);

  const handleCreateMessage = () => {
    if (newMessage) {
      createMessage(wrapTextLinesInParagraphs(newMessage));
      setNewMessage('');
    }

    setDisabledForm(true);
  };

  const handleChangeMessage = (message: string) => {
    setNewMessage(message);
    setDisabledForm(!message);
  };

  const handleSeenMessages = () => {
    const unreadMessages = messages.filter(
      (message) =>
        !message.seenData.map((message) => message.userId).includes(account.id)
    );

    if (unreadMessages.length) {
      const messagesIds = unreadMessages.map((message) => message.id);

      markAsSeenMessage(messagesIds);
      readMessagesOfTask(props.taskId);
    }
  };

  return (
    <S.Panel justify="space-between" vertical>
      <ChatModalCreateNew
        open={modalCreateVisible}
        company={props.company}
        onCancel={() => setModalCreateVisible(false)}
      />

      <ChatModalUploadFiles
        actionCreateMessage={createMessage}
        selectedId={props.taskId}
        companyId={props.details?.companyId}
        open={modalAttachVisible}
        onCancel={() => setModalAttachVisible(false)}
      />

      <ChatPanelHeader
        title={props.details?.title}
        actions={
          <S.Action onClick={() => setModalCreateVisible(true)}>
            Create new chat
          </S.Action>
        }
      />

      <Spin spinning={isLoading}>
        <ChatMessagesList
          selectedId={props.taskId}
          messages={messages}
          groupedMessages={groupedMessages}
          onRemove={deleteMessage}
        />
      </Spin>

      <S.Inner>
        <ChatPanelFormSend
          isUnreadMessages={isUnreadMessages}
          selectedId={props.taskId}
          value={newMessage}
          disabled={disabledForm || !props.taskId}
          onAttach={() => setModalAttachVisible(true)}
          onSend={handleCreateMessage}
          onChange={handleChangeMessage}
          onSeenMessages={handleSeenMessages}
        />
      </S.Inner>
    </S.Panel>
  );
};

export default ChatPanel;
