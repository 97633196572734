import { Button, Flex, Form, Modal, type ModalProps, Row } from 'antd';
import { Tooltip, message } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAdminCompanyController_updateOneById } from '@api-client/generated/AdminCompanyController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { IconInfo } from '@assets';
import { legalList, taxPeriodsList } from '@constants';
import { CompanyDetailsWrapperFormControl } from '@entities';
import { countries } from '@utils/countries';

import * as S from './styled';

type CompanyDetailsEditProps = {
  company: Schemas.AdminCompanyDto;
  onCancel: () => void;
  onAfterEdit: () => void;
} & ModalProps;

const CompanyDetailsEdit: FC<CompanyDetailsEditProps> = ({
  company,
  open,
  onAfterEdit,
  onCancel,
  ...rest
}) => {
  const { id: companyId } = useParams();

  const [form] = Form.useForm();

  const { mutate: updateCompany, isPending: loading } =
    useAdminCompanyController_updateOneById();

  useEffect(
    () => {
      if (company) {
        form.setFieldsValue({
          ...company,
          details: company.details?.incorporationDate
            ? {
                ...company.details,
                incorporationDate: dayjs(company.details?.incorporationDate),
              }
            : company.details,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [company]
  );

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleSubmit = (values: Schemas.AdminCompanyDto) => {
    if (company.details?.contactPerson && values.details) {
      values.details.contactPerson = company.details?.contactPerson;
    }

    updateCompany(
      {
        parameter: {
          id: companyId!,
        },
        requestBody: {
          ...company,
          ...values,
        },
      },
      {
        onSuccess: () => {
          message.success('Company information has been successfully updated');

          onAfterEdit();
          handleCancel();
        },
      }
    );
  };

  return (
    <Modal
      width={575}
      open={open}
      title="Edit company details"
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      centered
      {...rest}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        requiredMark={false}
      >
        <S.Card>
          <CompanyDetailsWrapperFormControl
            type="input"
            form={{
              label: 'Company name',
              name: 'name',
              rules: [{ required: true }],
            }}
          />

          <CompanyDetailsWrapperFormControl
            type="textarea"
            span={24}
            form={{
              label: 'Business activity',
              name: ['details', 'businessActivityDescription'],
            }}
            control={{
              rows: 3,
            }}
          />

          <Row gutter={[12, 0]}>
            <CompanyDetailsWrapperFormControl
              type="select"
              span={10}
              form={{
                label: 'Legal form',
                name: ['details', 'legalForm'],
              }}
              control={{
                options: legalList,
              }}
            />

            <CompanyDetailsWrapperFormControl
              type="input"
              span={14}
              form={{
                label: 'Client reference',
                name: 'referenceId',
              }}
            />
          </Row>
        </S.Card>

        <S.Card>
          <Row gutter={[12, 0]}>
            <CompanyDetailsWrapperFormControl
              type="input"
              span={10}
              form={{
                label: 'VAT ID',
                name: ['details', 'vatId'],
              }}
            />

            <CompanyDetailsWrapperFormControl
              type="input"
              span={7}
              form={{
                label: (
                  <Flex align="center" gap={6}>
                    Tax number
                    <Tooltip
                      title="The tax number was issued by the tax autority. Please note it's different from VAT ID."
                      placement="bottom"
                    >
                      <IconInfo />
                    </Tooltip>
                  </Flex>
                ),
                name: ['details', 'taxNumber'],
              }}
            />

            <CompanyDetailsWrapperFormControl
              type="select"
              span={7}
              form={{
                label: 'VAT periodicity',
                name: ['details', 'vatPeriodicity'],
              }}
              control={{
                options: taxPeriodsList,
              }}
            />
          </Row>
        </S.Card>

        <S.Card>
          <CompanyDetailsWrapperFormControl
            type="input"
            form={{
              label: 'Business license number',
              name: ['details', 'businessLicenseNumber'],
            }}
          />

          <Row gutter={[12, 0]}>
            <CompanyDetailsWrapperFormControl
              type="textarea"
              span={24}
              form={{
                label: 'Activity',
                name: ['details', 'businessLicenceActivityDescription'],
              }}
              control={{
                rows: 3,
              }}
            />
          </Row>
        </S.Card>

        <S.Card>
          <Row gutter={[12, 0]}>
            <CompanyDetailsWrapperFormControl
              type="input"
              span={10}
              form={{
                label: 'RCS number',
                name: ['details', 'rscNumber'],
              }}
            />

            <CompanyDetailsWrapperFormControl
              type="input-number"
              span={7}
              form={{
                label: 'Share capital',
                name: ['details', 'shareCapital'],
              }}
            />
          </Row>

          <Row gutter={[12, 0]}>
            <CompanyDetailsWrapperFormControl
              type="date-picker"
              span={10}
              form={{
                label: 'Incorporation date',
                name: ['details', 'incorporationDate'],
              }}
              control={{
                format: 'DD.MM.YYYY',
              }}
            />

            <CompanyDetailsWrapperFormControl
              type="input"
              span={7}
              form={{
                label: 'Financial year',
                name: ['details', 'financialYear'],
              }}
            />

            <CompanyDetailsWrapperFormControl
              type="input"
              span={7}
              form={{
                label: 'First financial year',
                name: ['details', 'firstFinancialYear'],
              }}
            />
          </Row>
        </S.Card>

        <S.Card>
          <Row gutter={[12, 0]}>
            <CompanyDetailsWrapperFormControl
              type="input"
              span={10}
              form={{
                label: 'Street',
                name: ['details', 'address', 'street'],
              }}
            />

            <CompanyDetailsWrapperFormControl
              type="input"
              span={7}
              form={{
                label: 'Number',
                name: ['details', 'address', 'houseNumber'],
              }}
            />

            <CompanyDetailsWrapperFormControl
              type="input"
              span={7}
              form={{
                label: 'Postal code',
                name: ['details', 'address', 'postalCode'],
              }}
            />

            <CompanyDetailsWrapperFormControl
              type="select"
              span={10}
              form={{
                label: 'Country',
                name: ['details', 'address', 'countryCode'],
              }}
              control={{
                options: countries.map((country) => ({
                  label: country.name,
                  value: country.value,
                })),
                virtual: true,
                optionFilterProp: 'label',
              }}
            />

            <CompanyDetailsWrapperFormControl
              type="input"
              span={14}
              form={{
                label: 'City',
                name: ['details', 'address', 'city'],
              }}
            />
          </Row>
        </S.Card>

        <S.Card>
          <Row gutter={[12, 0]}>
            <CompanyDetailsWrapperFormControl
              type="input"
              span={10}
              form={{
                label: 'Bank name',
                name: ['details', 'bankData', 'bankName'],
              }}
            />

            <CompanyDetailsWrapperFormControl
              type="input"
              span={14}
              form={{
                label: 'IBAN',
                name: ['details', 'bankData', 'iban'],
              }}
            />
          </Row>
        </S.Card>

        <S.Submit>
          <Form.Item noStyle>
            <Flex justify="flex-end" gap={12}>
              <Button onClick={handleCancel} size="large">
                Cancel
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
              >
                Save
              </Button>
            </Flex>
          </Form.Item>
        </S.Submit>
      </Form>
    </Modal>
  );
};

export default CompanyDetailsEdit;
