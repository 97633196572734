import { Flex } from 'antd';

import styled from '@emotion/styled';
import { colors } from '@theme';

type FileProps = {
  selectedFile: boolean;
  selectedMode: boolean;
  status: string | null;
  light: boolean;
};

export const File = styled(Flex, {
  shouldForwardProp: (prop) =>
    prop !== 'selectedFile' && prop !== 'selectedMode' && prop !== 'light',
})<FileProps>`
  background-color: ${({ selectedFile, light }) => {
    if (selectedFile) return colors.bgBrightBlue200;
    if (light) return colors.white;

    return colors.bgGrey;
  }};
  border: 1px solid
    ${({ status, selectedFile }) => {
      if (status === 'errorsFound') return colors.error;
      if (selectedFile) return colors.primary;

      return colors.strokeGrey;
    }};
  border-radius: 10px;
  cursor: pointer;
  padding: ${({ selectedMode }) => (selectedMode ? '24px 20px' : '16px')};
  margin-top: ${({ selectedMode }) => (selectedMode ? '12px' : '4px')};
`;

export const Wrapper = styled(Flex)`
  width: calc(100% - 70px);
`;

export const Inner = styled(Flex)`
  width: 100%;
  flex: 1;
`;

export const Name = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selectedMode',
})<{ selectedMode: FileProps['selectedMode'] }>`
  width: 100%;
  max-width: ${({ selectedMode }) => (selectedMode ? '100%' : '220px')};
  min-width: 150px;
  color: ${({ theme }) => theme.colors.text400};
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Size = styled.div`
  color: ${({ theme }) => theme.colors.text200};
  font-size: 12px;
`;

export const Processing = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
`;
