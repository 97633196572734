import { Flex } from 'antd';

import styled from '@emotion/styled';

type ItemProps = {
  isSelected: boolean;
};

type StatusUnit = 'active' | 'archived';

export const Item = styled(Flex, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<ItemProps>`
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primary : theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  cursor: pointer;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.white : theme.colors.black};
  padding: 16px;

  &:first-of-type {
    border-radius: 10px 10px 0 0;
  }

  &:last-child {
    border-radius: 0 0 10px 10px;
  }

  &:only-child {
    border-radius: 10px;
  }
`;

export const Inner = styled(Flex)`
  width: calc(100% - 56px);
  flex: 1;
`;

export const Avatar = styled.div`
  width: 44px;
  height: 44px;
  background-color: ${({ theme }) => theme.colors.gray};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div<{ type: StatusUnit }>`
  color: ${({ theme, type }) => type === 'archived' && theme.colors.text200};
  font-weight: 600;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Details = styled.div<ItemProps>`
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.white : theme.colors.text400};
  font-size: 12px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Type = styled.div`
  color: ${({ theme }) => theme.colors.text200};
  font-size: 12px;
`;

export const Count = styled.div<ItemProps>`
  width: 18px;
  height: 18px;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.white : theme.colors.primary};
  border-radius: 50%;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primary : theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 10px;
`;

export const Date = styled.div<ItemProps & { isGeneral: boolean }>`
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.white : theme.colors.text200};
  font-size: ${({ isGeneral }) => `${isGeneral ? 12 : 10}px`};
`;

export const Status = styled.div<ItemProps & { type: StatusUnit }>`
  color: ${({ theme, type, isSelected }) => {
    if (isSelected) {
      return theme.colors.white;
    }

    if (type === 'active') {
      return theme.colors.success;
    }

    return theme.colors.text200;
  }};
  font-weight: 600;
  font-size: 12px;
`;
