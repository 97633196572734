import { Button, Flex, Spin, Tabs } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAdminBillingPeriodController_delete } from '@api-client/generated/AdminBillingPeriodController/delete';
import { Schemas } from '@api-client/generated/types';
import { IconEditUnderline, IconPlus, IconTrash } from '@assets';
import { Scrollbar } from '@components';
import { PopupDelete } from '@entities';
import { useAccount } from '@hooks';

import BillingTabDetails from '../../tabs/BillingTabDetails';
import BillingTabDocument from '../../tabs/BillingTabDocument';
import BillingCardSkeleton from '../BillingCardSkeleton';
import * as S from './styled';

enum TabKey {
  Details = 'details',
  PaymentDocument = 'paymentDocument',
}

type InvoiceItem = Schemas.InvoiceItem;

type BillingCardProps = {
  details: Schemas.BillingPeriod | null;
  isLoading: boolean;
  onAdd: VoidFunction;
  onEdit: VoidFunction;
  onEditService: (details: InvoiceItem) => void;
  onAfterCreateDocument: (id: string) => void;
  onAfterDeleteDocument: VoidFunction;
  onAfterDelete: (id: string) => void;
};

const getDatePeriod = (period: string) => dayjs(period).format('DD.MM.YYYY');

const BillingCard = (props: BillingCardProps) => {
  const navigate = useNavigate();

  const { id: billingId } = useParams();

  const { companyId } = useAccount();
  const [selectedTabKey, setSelectedTabKey] = useState<TabKey | string>(
    TabKey.Details
  );
  const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false);

  const { mutate: removeBilling } = useAdminBillingPeriodController_delete();

  useEffect(() => {
    if (billingId) {
      setSelectedTabKey(TabKey.Details);
    }
  }, [billingId]);

  const handleRemoveBilling = () => {
    const id = props.details?.id;

    if (id) {
      removeBilling(
        {
          parameter: {
            companyId: companyId!,
            id,
          },
        },
        {
          onSuccess: () => {
            setIsOpenPopupConfirm(false);
            props.onAfterDelete?.(id);

            navigate('/billing');
          },
        }
      );
    }
  };

  const handleAfterDelete = () => {
    setSelectedTabKey(TabKey.Details);
    props.onAfterDeleteDocument();
  };

  if (props.isLoading) {
    return (
      <S.Container justify="center" align="center">
        <Spin />
      </S.Container>
    );
  }

  if (!props.details) {
    return (
      <S.Container>
        <BillingCardSkeleton />
      </S.Container>
    );
  }

  return (
    <S.Container vertical>
      <PopupDelete
        open={isOpenPopupConfirm}
        title="Are you sure you want to delete the billing?"
        confirmParams={{
          text: 'Yes, delete',
        }}
        onRemove={handleRemoveBilling}
        onCancel={() => setIsOpenPopupConfirm(false)}
      />

      <S.Header align="flex-start" justify="space-between" gap={16}>
        <Flex vertical>
          <S.Title align="center" gap={8}>
            {getDatePeriod(props.details.startDate)} -{' '}
            {getDatePeriod(props.details.endDate)}
            <S.ButtonEdit
              type="link"
              icon={<IconEditUnderline />}
              size="large"
              onClick={props.onEdit}
            />
          </S.Title>

          <S.Details>{props.details.items.length} services</S.Details>
        </Flex>


        {!props.details?.billingDocument && (
          <Flex gap={8}>
            <Button
              type="default"
              size="large"
              icon={<IconPlus />}
              onClick={props.onAdd}
              ghost
            >
              Add service
            </Button>

            <Button
              type="primary"
              size="large"
              icon={<IconTrash />}
              onClick={() => setIsOpenPopupConfirm(true)}
              danger
            />
        </Flex>
        )}
      </S.Header>

      <Tabs
        activeKey={selectedTabKey}
        size="large"
        items={[
          {
            key: TabKey.Details,
            label: 'Details',
            children: (
              <Scrollbar height="calc(100vh - 365px)" withoutPadding>
                <BillingTabDetails
                  dataSource={props.details.items}
                  onAdd={props.onAdd}
                  onEdit={props.onEditService}
                />
              </Scrollbar>
            ),
          },
          {
            key: TabKey.PaymentDocument,
            label: 'Payment Document',
            children: (
              <Scrollbar height="calc(100vh - 365px)" withoutPadding>
                <BillingTabDocument
                  periodId={props.details.id}
                  documentId={props.details.billingDocument?.id}
                  onAfterCreate={props.onAfterCreateDocument}
                  onAfterDelete={handleAfterDelete}
                />
              </Scrollbar>
            ),
          },
        ]}
        onChange={setSelectedTabKey}
      />
    </S.Container>
  );
};

export default BillingCard;
