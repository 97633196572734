import { Flex } from 'antd';

import styled from '@emotion/styled';

export const Inner = styled(Flex)`
  .ui-form-item {
    margin-bottom: 16px;
  }
`;

export const Attach = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 16px;
  margin: 8px 0 28px 0;
`;
