import { Button, Flex, Spin } from 'antd';
import { MouseEvent } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Schemas } from '@api-client/generated/types';
import { IconClose, IconDownload, IconEye } from '@assets';
import { StatusCircle } from '@entities';
import { downloadFile, formatFileSize } from '@utils';

import * as S from './styled';

type CustomMouseEvent = MouseEvent & {
  domEvent?: MouseEvent;
};

type TaskFile = Schemas.TaskFile;

type ChatFilesItemProps = {
  file: TaskFile & { status?: string };
  light?: boolean;
  selectedFile?: TaskFile | null;
  selectedMode?: boolean;
  onView?: () => void;
  onSelect?: (e: MouseEvent) => void;
  onRemove?: (id: string) => void;
};

const ChatFilesItem = ({
  file,
  light,
  selectedFile,
  selectedMode,
  onView,
  onSelect,
  onRemove,
}: ChatFilesItemProps) => {
  const isTypeImage = file?.mimeType.includes('image');
  const isTypePDF = file?.mimeType.includes('pdf');

  const isNotProcessing = file.status !== 'processing';
  const isNotError = file.status !== 'errorsFound';

  const handleSelect = (e: CustomMouseEvent) => {
    e.domEvent?.stopPropagation();

    onSelect?.(e);
  };

  return (
    <S.File
      align="center"
      justify="space-between"
      gap={12}
      status={file.status!}
      light={light!}
      selectedFile={selectedFile ? selectedFile.id === file.id : false}
      selectedMode={selectedMode!}
      onClick={handleSelect}
    >
      <S.Wrapper gap={12}>
        {!isNotProcessing && <Spin indicator={<LoadingOutlined spin />} />}

        {!isNotError && <StatusCircle size={20} status="errorsFound" />}

        <S.Inner gap={4} vertical>
          <S.Name selectedMode={selectedMode!}>{file.fileName}</S.Name>

          {!isNotProcessing && (
            <S.Processing>In the process of uploading...</S.Processing>
          )}

          {isNotProcessing && file.fileSize && (
            <S.Size>{formatFileSize(file.fileSize)}</S.Size>
          )}
        </S.Inner>
      </S.Wrapper>

      <Flex align="center" gap={8}>
        {isNotProcessing &&
          isNotError &&
          !selectedMode &&
          (isTypePDF || isTypeImage) && (
            <Button
              type="text"
              size="small"
              icon={<IconEye />}
              onClick={onView}
            />
          )}

        {isNotProcessing && isNotError && (
          <Button
            type="text"
            size="small"
            icon={<IconDownload />}
            onClick={() => downloadFile(file.url, file.fileName, true)}
          />
        )}

        {(onRemove || !isNotProcessing) && isNotError && (
          <Button
            type="text"
            size="small"
            icon={<IconClose />}
            onClick={() => onRemove?.(file.id)}
          />
        )}
      </Flex>
    </S.File>
  );
};

export default ChatFilesItem;
