import { Flex } from 'antd';
import { rgba } from 'emotion-rgba';

import styled from '@emotion/styled';

export const Overlay = styled(Flex)`
  width: 100%;
  background-color: ${({ theme }) => rgba(theme.colors.dark, 0.7)};
  border-radius: 10px;
  padding: 16px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;
