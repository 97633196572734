import { List } from 'antd';
import { useState } from 'react';

import { Schemas } from '@api-client/generated/types';

import ChatModalFileViewer from '../../modals/ChatModalFileViewer';
import ChatFilesItem from '../ChatFilesItem';
import * as S from './styled';

type TaskFile = Schemas.TaskFile;

type ChatFilesListProps = {
  dataSource: TaskFile[];
  light?: boolean;
  selectedFile?: TaskFile | null;
  selectedMode?: boolean;
  onSelect?: (file: TaskFile) => void;
  onRemove?: (id: string) => void;
};

const ChatFilesList = (props: ChatFilesListProps) => {
  const [fileViewerVisible, setFileViewerVisible] = useState(false);
  const [selectedFileView, setSelectedFileView] = useState<TaskFile | null>(
    null
  );

  const handleViewFile = (file: TaskFile) => {
    setSelectedFileView(file);
    setFileViewerVisible(true);
  };

  return (
    <S.Container>
      <ChatModalFileViewer
        open={fileViewerVisible}
        file={selectedFileView as TaskFile}
        onCancel={() => setFileViewerVisible(false)}
      />

      <List
        dataSource={props.dataSource}
        renderItem={(file) => (
          <ChatFilesItem
            file={file}
            onSelect={() => props.onSelect?.(file)}
            light={props.light}
            selectedFile={props.selectedFile}
            selectedMode={props.selectedMode}
            onRemove={props.onRemove}
            onView={() => handleViewFile(file)}
          />
        )}
        locale={{
          emptyText: <div />,
        }}
      />
    </S.Container>
  );
};
export default ChatFilesList;
