import { List } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { getDateRelative } from '@utils';

import ChatDialogsItem from '../ChatDialogsItem';

type ChatDialogsListProps = {
  type?: 'general';
  tasks: Schemas.Task[];
};

const ChatDialogsList = ({ type, tasks }: ChatDialogsListProps) => {
  const { companyId, id: taskId } = useParams();
  const navigate = useNavigate();

  const isGeneral = type === 'general';

  return (
    <List
      dataSource={tasks}
      renderItem={(item) => (
        <ChatDialogsItem
          title={item.title}
          date={getDateRelative(item.createdAt)}
          unseenMessagesCount={item.unseenMessagesCount}
          isSelected={item.id === taskId}
          isGeneral={isGeneral}
          isArchived={!!item.closedAt}
          onClick={() => navigate(`/chat-tasks/${companyId}/chats/${item.id}`)}
        />
      )}
      locale={{ emptyText: <div /> }}
    />
  );
};

export default ChatDialogsList;
